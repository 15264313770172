import React from "react"
import { useTranslation } from "react-i18next"
import "./customer-header.sass"
import { EditOutlined } from "@mui/icons-material"
import dayjs from "dayjs"
import { IconButton } from "@mui/material"
import { CustomerFragment } from "../../graphql"
import Avatar from "../Avatar"
import Tooltip from "../Tooltip"

type Props = {
  customer: CustomerFragment
}

export default function CustomerHeader({ customer }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerHeader" })

  return (
    <div className="cp_customer-overview-header">
      <div className="cp_customer-overview-header_info-container">
        <div className="cp_customer-overview-header_avatar-container">
          <Avatar size="lg" src={ customer.company?.logo?.url.address } className="cp_customer-overview-header_avatar">
            { customer.company?.name[0] }
          </Avatar>
        </div>
        <div className="cp_customer-overview-header_details">
          <p className="cp_customer-overview-header_group-name">
            { customer.company?.name }
          </p>
          <p className="cp_customer-overview-header_created">
            { `${ translate("Created") } ${ dayjs(customer.created * 1000)
              .format("MMM D, YYYY") }` }
          </p>
        </div>
      </div>
      <div className="cp_customer-overview-header_stats">
        <div className="cp_customer-overview-header_user-count-container">
          <h4>{ translate("USERS") }</h4>
          <h5>{ customer.userCount }</h5>
        </div>
        <Tooltip title={ translate("Edit") }>
          <IconButton size="small">
            <EditOutlined />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}
