import React, { JSX } from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import { useDispatch, useSelector } from "react-redux"
import SlidingPanel from "../../../SlidingPanel"
import "./communicationsSlidingPanel.sass"
import * as GraphQL from "../../../../graphql"
import Tabs, { TabLabelProps } from "../../../Tabs"
import CommunicationsContent from "./CommunicationsContent"
import TemplatesContent from "./TemplatesContent"
import { FilterMenuOption } from "../../../FilterMenu"
import { RootState } from "../../../../state/store"
import { setCommunicationsTab } from "../../../../state/slidingPanelSlice"

type Props = {
  open: boolean
  onClose: () => void
}

function CommunicationsSlidingPanel({
  open,
  onClose,
}: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NavigationBar" })
  const dispatch = useDispatch()

  const { communicationsTab } = useSelector((root: RootState) => root.slidingPanels)

  const tabs: TabLabelProps[] = [
    { label: translate("Groups") }, { label: translate("Templates") },
  ]

  const filterOptions: FilterMenuOption[] = [ {
    label: translate("Filter By"),
    values: [
      {
        label: translate("All"), value: null, keyId: uuidv4(),
      },
      {
        label: translate("Read Messages"), value: GraphQL.SearchCommunicationGroupFilter.Read, keyId: uuidv4(),
      },
      {
        label: translate("Unread Messages"), value: GraphQL.SearchCommunicationGroupFilter.Unread, keyId: uuidv4(),
      },
      {
        label: translate("Notifications On"), value: GraphQL.SearchCommunicationGroupFilter.Subscribed, keyId: uuidv4(),
      },
      {
        label: translate("Notifications Off"), value: GraphQL.SearchCommunicationGroupFilter.Unsubscribed, keyId: uuidv4(),
      } ],
  } ]

  return (
    <SlidingPanel
      className="cp_component_navigation-bar-comm-sliding-panel"
      title={ translate("Communications") }
      open={ open }
      onClose={ onClose }
      disablePortal={ true }
    >
      <Tabs
        className="cp_component_navigation-bar-comm-tabs"
        tabs={ tabs }
        handleChange={ (index) => dispatch(setCommunicationsTab(index)) }
        defaultValue={ communicationsTab }
      />

      { communicationsTab === 0
        ? (
          <CommunicationsContent
            filterOptions={ filterOptions }
          />
        )
        : (
          <TemplatesContent />
        )
        }
    </SlidingPanel>
  )
}

export default CommunicationsSlidingPanel
