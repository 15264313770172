import { MutationOptions } from "@apollo/client"

import * as API from "./index"
import * as GraphQL from "../../graphql"
import { Status } from "../types"

export const addSocialAccountToSuggestionList = async ({
  hoarderAccountIds,
  suggestionListId,
}: GraphQL.AddSocialAccountToSuggestionListMutationVariables) => (
  API.mutate<
  GraphQL.AddSocialAccountToSuggestionListMutation,
  GraphQL.AddSocialAccountToSuggestionListMutationVariables
  >(GraphQL.AddSocialAccountToSuggestionListDocument, { hoarderAccountIds, suggestionListId })
)

export const addAccountToSuggestionList = async ({
  suggestionListId,
  suggestionListCategoryId,
  hoarderAccountIds,
}: GraphQL.SuggestionListSocialAccountAddMutationVariables) => (
  API.mutate<
  GraphQL.SuggestionListSocialAccountAddMutation,
  GraphQL.SuggestionListSocialAccountAddMutationVariables
  >(GraphQL.SuggestionListSocialAccountAddDocument, {
    suggestionListId,
    suggestionListCategoryId,
    hoarderAccountIds,
  })
)

export const archiveSuggestionList = async (
  params: GraphQL.ArchiveSuggestionListMutationVariables,
) => (
  API.mutate<
  GraphQL.ArchiveSuggestionListMutation,
  GraphQL.ArchiveSuggestionListMutationVariables
  >(GraphQL.ArchiveSuggestionListDocument, params)
)

export const archiveSuggestionListGroup = async (
  params: GraphQL.ArchiveSuggestionListGroupMutationVariables,
) => (
  API.mutate<
  GraphQL.ArchiveSuggestionListGroupMutation,
  GraphQL.ArchiveSuggestionListGroupMutationVariables
  >(GraphQL.ArchiveSuggestionListGroupDocument, params)
)

export const createCampaignNetworkAccount = async (
  {
    campaignId,
    networkAccountId,
  }: GraphQL.CreateCampaignNetworkAccountMutationVariables,
): Promise<Status<GraphQL.CreateCampaignNetworkAccountMutation | null>> => (
  API.mutate<
    GraphQL.CreateCampaignNetworkAccountMutation,
    GraphQL.CreateCampaignNetworkAccountMutationVariables
    >(GraphQL.CreateCampaignNetworkAccountDocument, { campaignId, networkAccountId })
)

export const createPersonalityNote = async (
  params: GraphQL.CreatePersonalityNoteMutationVariables,
) => (
  API.mutate<
    GraphQL.CreatePersonalityNoteMutation,
    GraphQL.CreatePersonalityNoteMutationVariables
  >(GraphQL.CreatePersonalityNoteDocument, params)
)

export const createSuggestionListGroup = async (
  params: GraphQL.CreateSuggestionListGroupMutationVariables,
) => (
  API.mutate<
    GraphQL.CreateSuggestionListGroupMutation,
    GraphQL.CreateSuggestionListGroupMutationVariables
  >(GraphQL.CreateSuggestionListGroupDocument, params)
)

export const createList = async (
  {
    affinities,
    avatarId,
    categories,
    clientIds,
    clientName,
    contextualRelevancy,
    description,
    groupId,
    keywords,
    imageTags,
    maxAge,
    minAge,
    name,
    network,
    isPublic,
    sexes,
    subscribedUserIds,
    suggestionAccessCode,
    suggestionListMode,
    suggestionListVerticalIds,
    toggles,
    vetting,
    vettingSubscriptionUserIds,
    demographicScoreMinAge,
    demographicScoreMaxAge,
    demographicScoreGender,
    demographicScoreEthnicity,
    demographicScoreFamily,
    demographicScoreReligion,
    demographicScoreIncome,
    demographicScoreLocations,
    demographicScoreOccupations,
    demographicScoreAffinities,
  }: GraphQL.CreateListMutationVariables,
): Promise<Status<GraphQL.CreateListMutation | null>> => (
  API.mutate<
    GraphQL.CreateListMutation,
    GraphQL.CreateListMutationVariables
    >(GraphQL.CreateListDocument, {
      affinities,
      avatarId,
      categories,
      clientIds,
      clientName,
      contextualRelevancy,
      description,
      groupId,
      keywords,
      imageTags,
      maxAge,
      minAge,
      name,
      network,
      isPublic,
      sexes,
      subscribedUserIds,
      suggestionAccessCode,
      suggestionListMode,
      suggestionListVerticalIds,
      toggles,
      vetting,
      vettingSubscriptionUserIds,
      demographicScoreMinAge,
      demographicScoreMaxAge,
      demographicScoreGender,
      demographicScoreEthnicity,
      demographicScoreFamily,
      demographicScoreReligion,
      demographicScoreIncome,
      demographicScoreLocations,
      demographicScoreOccupations,
      demographicScoreAffinities,
    })
)

export const createNetworkAccountEmail = async (
  { networkAccountId, emailAddress }: GraphQL.CreateNetworkAccountEmailMutationVariables,
  refetchQueries?: MutationOptions["refetchQueries"],
): Promise<Status<GraphQL.CreateNetworkAccountEmailMutation | null>> => (
  API.mutate<
    GraphQL.CreateNetworkAccountEmailMutation,
    GraphQL.CreateNetworkAccountEmailMutationVariables
    >(
      GraphQL.CreateNetworkAccountEmailDocument,
      { networkAccountId, emailAddress },
      refetchQueries,
    )
)

export const deleteContentAlert = async (
  params: GraphQL.DeleteContentAlertMutationVariables,
) => (
  API.mutate<
    GraphQL.DeleteContentAlertMutation,
    GraphQL.DeleteContentAlertMutationVariables
  >(GraphQL.DeleteContentAlertDocument, params)
)

export const deleteDeliverable = async (
  params: GraphQL.DeleteDeliverableMutationVariables,
) => (
  API.mutate<
    GraphQL.DeleteDeliverableMutation,
    GraphQL.DeleteDeliverableMutationVariables
  >(GraphQL.DeleteDeliverableDocument, params)
)

export const deleteNetworkAccountEmail = async (
  { networkAccountId, emailId }: GraphQL.DeleteNetworkAccountEmailMutationVariables,
): Promise<Status<GraphQL.DeleteNetworkAccountEmailMutation | null>> => (
  API.mutate<
          GraphQL.DeleteNetworkAccountEmailMutation,
          GraphQL.DeleteNetworkAccountEmailMutationVariables
          >(GraphQL.DeleteNetworkAccountEmailDocument, { networkAccountId, emailId })
)

export const deletePersonality = async (
  { id }: GraphQL.DeletePersonalityMutationVariables,
): Promise<Status<GraphQL.DeletePersonalityMutation | null>> => (
  API.mutate<
    GraphQL.DeletePersonalityMutation,
    GraphQL.DeletePersonalityMutationVariables
  >(GraphQL.DeletePersonalityDocument, { id }, undefined, (cache) => {
    cache.evict({ id: `Personality:${ id }` })
    cache.gc()
  })
)

export const publicApproveSuggestionListSocialAccount = async (
  params: GraphQL.PublicApproveSuggestionListSocialAccountMutationVariables,
) => (
  API.mutate<
    GraphQL.PublicApproveSuggestionListSocialAccountMutation,
    GraphQL.PublicApproveSuggestionListSocialAccountMutationVariables
  >(GraphQL.PublicApproveSuggestionListSocialAccountDocument, params)
)

export const publicCreateSuggestionListSocialAccountFeedback = async (
  params: GraphQL.PublicCreateSuggestionListSocialAccountFeedbackMutationVariables,
) => (
  API.mutate<
    GraphQL.PublicCreateSuggestionListSocialAccountFeedbackMutation,
    GraphQL.PublicCreateSuggestionListSocialAccountFeedbackMutationVariables
  >(GraphQL.PublicCreateSuggestionListSocialAccountFeedbackDocument, params)
)

export const setPrimaryNetworkAccountEmail = async (
  { networkAccountId, email }: GraphQL.SetNetworkAccountPrimaryEmailMutationVariables,
  refetchQueries?: MutationOptions["refetchQueries"],
): Promise<Status<GraphQL.Network | null>> => (
  API.mutate<
  GraphQL.Network,
  GraphQL.SetNetworkAccountPrimaryEmailMutationVariables
  >(
    GraphQL.SetNetworkAccountPrimaryEmailDocument,
    { networkAccountId, email },
    refetchQueries,
  )
)

export const unarchiveSuggestionList = async (
  params: GraphQL.UnarchiveSuggestionListMutationVariables,
) => (
  API.mutate<
    GraphQL.UnarchiveSuggestionListMutation,
    GraphQL.UnarchiveSuggestionListMutationVariables
  >(GraphQL.UnarchiveSuggestionListDocument, params)
)

export const unarchiveSuggestionListGroup = async (
  params: GraphQL.UnarchiveSuggestionListGroupMutationVariables,
) => (
  API.mutate<
    GraphQL.UnarchiveSuggestionListGroupMutation,
    GraphQL.UnarchiveSuggestionListGroupMutationVariables
  >(GraphQL.UnarchiveSuggestionListGroupDocument, params)
)

export const updateAdSet = async (
  params: GraphQL.UpdateAdSetMutationVariables,
) => (
  API.mutate<
    GraphQL.UpdateAdSetMutation,
    GraphQL.UpdateAdSetMutationVariables
  >(GraphQL.UpdateAdSetDocument, params)
)

export const updateCampaignCommunicationSettings = (
  params: GraphQL.UpdateCampaignCommunicationSettingsMutationVariables,
) => (
  API.mutate<
    GraphQL.UpdateCampaignCommunicationSettingsMutation,
    GraphQL.UpdateCampaignCommunicationSettingsMutationVariables
  >(GraphQL.UpdateCampaignCommunicationSettingsDocument, params)
)

export const updateCampaignBrandManagers = async (
  params: GraphQL.UpdateCampaignPermissionsMutationVariables,
) => (
  API.mutate<
    GraphQL.UpdateCampaignPermissionsMutation,
    GraphQL.UpdateCampaignPermissionsMutationVariables
  >(GraphQL.UpdateCampaignPermissionsDocument, params)
)

export const updateCampaignSubscriptions = async (
  params: GraphQL.UpdateCampaignSubscriptionsMutationVariables,
) => (
  API.mutate<
    GraphQL.UpdateCampaignSubscriptionsMutation,
    GraphQL.UpdateCampaignSubscriptionsMutationVariables
  >(GraphQL.UpdateCampaignSubscriptionsDocument, params)
)

export const updateDeliverableMetrics = async (
  params: GraphQL.UpdateDeliverableMetricsMutationVariables,
) => (
  API.mutate<
    GraphQL.UpdateDeliverableMetricsMutation,
    GraphQL.UpdateDeliverableMetricsMutationVariables
  >(GraphQL.UpdateDeliverableMetricsDocument, params)
)

export const updateDeliverableUrl = async (
  params: GraphQL.UpdateDeliverableLiveStatusUrlMutationVariables,
) => (
  API.mutate<
    GraphQL.UpdateDeliverableLiveStatusUrlMutation,
    GraphQL.UpdateDeliverableLiveStatusUrlMutationVariables
  >(GraphQL.UpdateDeliverableLiveStatusUrlDocument, params)
)

export const updatePersonalityManagers = async (
  { id, managerIds }: GraphQL.UpdatePersonalityManagersMutationVariables,
): Promise<Status<GraphQL.UpdatePersonalityManagersMutation | null>> => (
  API.mutate<
    GraphQL.UpdatePersonalityManagersMutation,
    GraphQL.UpdatePersonalityManagersMutationVariables
  >(GraphQL.UpdatePersonalityManagersDocument, {
    id,
    managerIds,
  })
)

export const updateSuggestionListSocialAccount = async (
  params: GraphQL.UpdateSuggestionListSocialAccountMutationVariables,
) => (
  API.mutate<
    GraphQL.UpdateSuggestionListSocialAccountMutation,
    GraphQL.UpdateSuggestionListSocialAccountMutationVariables
  >(GraphQL.UpdateSuggestionListSocialAccountDocument, params)
)

export const reorderSuggestionListSocialAccount = async (
  params: GraphQL.SuggestionListSocialAccountReorderOrdinalMutationVariables,
): Promise<Status<GraphQL.SuggestionListSocialAccountReorderOrdinalMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListSocialAccountReorderOrdinalMutation,
    GraphQL.SuggestionListSocialAccountReorderOrdinalMutationVariables
  >(GraphQL.SuggestionListSocialAccountReorderOrdinalDocument, params)
)

export const addSuggestionListCategory = async (
  params: GraphQL.SuggestionListCategoryAddMutationVariables,
): Promise<Status<GraphQL.SuggestionListCategoryAddMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListCategoryAddMutation,
    GraphQL.SuggestionListCategoryAddMutationVariables
  >(GraphQL.SuggestionListCategoryAddDocument, params)
)

export const renameSuggestionListCategory = async (
  params: GraphQL.SuggestionListCategoryRenameMutationVariables,
): Promise<Status<GraphQL.SuggestionListCategoryRenameMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListCategoryRenameMutation,
    GraphQL.SuggestionListCategoryRenameMutationVariables
  >(GraphQL.SuggestionListCategoryRenameDocument, params)
)

export const removeSuggestionListAccount = async (
  params: GraphQL.SuggestionListSocialAccountRemoveMutationVariables,
): Promise<Status<GraphQL.SuggestionListSocialAccountRemoveMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListSocialAccountRemoveMutation,
    GraphQL.SuggestionListSocialAccountRemoveMutationVariables
  >(GraphQL.SuggestionListSocialAccountRemoveDocument, params)
)

export const deleteSuggestionListCategory = async (
  params: GraphQL.SuggestionListCategoryDeleteMutationVariables,
): Promise<Status<GraphQL.SuggestionListCategoryDeleteMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListCategoryDeleteMutation,
    GraphQL.SuggestionListCategoryDeleteMutationVariables
  >(GraphQL.SuggestionListCategoryDeleteDocument, params)
)

export const reorderSuggestionListCategory = async (
  params: GraphQL.SuggestionListCategoryReorderOrdinalMutationVariables,
): Promise<Status<GraphQL.SuggestionListCategoryReorderOrdinalMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListCategoryReorderOrdinalMutation,
    GraphQL.SuggestionListCategoryReorderOrdinalMutationVariables
  >(GraphQL.SuggestionListCategoryReorderOrdinalDocument, params)
)
export const updateSuggestionListSocialAccountApprovalStatus = async (
  params: GraphQL.UpdateSuggestionListSocialAccountApprovalStatusMutationVariables,
): Promise<Status<GraphQL.UpdateSuggestionListSocialAccountApprovalStatusMutation | null>> => (
  API.mutate<
    GraphQL.UpdateSuggestionListSocialAccountApprovalStatusMutation,
    GraphQL.UpdateSuggestionListSocialAccountApprovalStatusMutationVariables
  >(GraphQL.UpdateSuggestionListSocialAccountApprovalStatusDocument, params)
)
export const createSuggestionListSocialAccountComment = async (
  params: GraphQL.CreateSuggestionListSocialAccountFeedbackMutationVariables,
): Promise<Status<GraphQL.CreateSuggestionListSocialAccountFeedbackMutation | null>> => (
  API.mutate<
    GraphQL.CreateSuggestionListSocialAccountFeedbackMutation,
    GraphQL.CreateSuggestionListSocialAccountFeedbackMutationVariables
  >(GraphQL.CreateSuggestionListSocialAccountFeedbackDocument, params)
)

export const createSuggestionListSocialAccountInternalNote = async (
  params: GraphQL.CreateSuggestionListSocialAccountInternalNoteMutationVariables,
): Promise<Status<GraphQL.CreateSuggestionListSocialAccountInternalNoteMutation | null>> => (
  API.mutate<
    GraphQL.CreateSuggestionListSocialAccountInternalNoteMutation,
    GraphQL.CreateSuggestionListSocialAccountInternalNoteMutationVariables
  >(GraphQL.CreateSuggestionListSocialAccountInternalNoteDocument, params)
)

export const removeSuggestionListFromGroup = async (
  params: GraphQL.SuggestionListRemoveFromGroupMutationVariables,
) => API.mutate<
      GraphQL.SuggestionListRemoveFromGroupMutation,
      GraphQL.SuggestionListRemoveFromGroupMutationVariables
    >(GraphQL.SuggestionListRemoveFromGroupDocument, params)

export const updateList = async (
  {
    id,
    affinities,
    avatarId,
    categories,
    clientIds,
    clientName,
    contextualRelevancy,
    description,
    keywords,
    imageTags,
    maxAge,
    minAge,
    name,
    network,
    isPublic,
    sexes,
    subscribedUserIds,
    suggestionAccessCode,
    suggestionListMode,
    suggestionListVerticalIds,
    toggles,
    vetting,
    vettingSubscriptionUserIds,
    demographicScoreMinAge,
    demographicScoreMaxAge,
    demographicScoreGender,
    demographicScoreEthnicity,
    demographicScoreFamily,
    demographicScoreReligion,
    demographicScoreIncome,
    demographicScoreLocations,
    demographicScoreOccupations,
    demographicScoreAffinities,
  }: GraphQL.UpdateSuggestionListMutationVariables,
): Promise<Status<GraphQL.UpdateSuggestionListMutation | null>> => (
  API.mutate<
    GraphQL.UpdateSuggestionListMutation,
    GraphQL.UpdateSuggestionListMutationVariables
    >(GraphQL.UpdateSuggestionListDocument, {
      id,
      affinities,
      avatarId,
      categories,
      clientIds,
      clientName,
      contextualRelevancy,
      description,
      keywords,
      imageTags,
      maxAge,
      minAge,
      name,
      network,
      isPublic,
      sexes,
      subscribedUserIds,
      suggestionAccessCode,
      suggestionListMode,
      suggestionListVerticalIds,
      toggles,
      vetting,
      vettingSubscriptionUserIds,
      demographicScoreMinAge,
      demographicScoreMaxAge,
      demographicScoreGender,
      demographicScoreEthnicity,
      demographicScoreFamily,
      demographicScoreReligion,
      demographicScoreIncome,
      demographicScoreLocations,
      demographicScoreOccupations,
      demographicScoreAffinities,
    })
)

export const createCampaign = async (
  params: GraphQL.CreateCampaignMutationVariables,
): Promise<Status<GraphQL.CreateCampaignMutation | null>> => (
  API.mutate<
    GraphQL.CreateCampaignMutation,
    GraphQL.CreateCampaignMutationVariables
  >(GraphQL.CreateCampaignDocument, params)
)

export const updateCampaign = async (
  params: GraphQL.UpdateCampaignMutationVariables,
): Promise<Status<GraphQL.UpdateCampaignMutation | null>> => (
  API.mutate<
    GraphQL.UpdateCampaignMutation,
    GraphQL.UpdateCampaignMutationVariables
  >(GraphQL.UpdateCampaignDocument, params)
)

export const updateCampaignReport = async (
  params: GraphQL.UpdateCampaignReportMutationVariables,
): Promise<Status<GraphQL.UpdateCampaignReportMutation | null>> => (
  API.mutate<
    GraphQL.UpdateCampaignReportMutation,
    GraphQL.UpdateCampaignReportMutationVariables
  >(GraphQL.UpdateCampaignReportDocument, params)
)

export const removeListTopPost = async (
  { input }: GraphQL.SuggestionListSocialAccountCippusRemoveMutationVariables,
): Promise<Status<GraphQL.SuggestionListSocialAccountCippusRemoveMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListSocialAccountCippusRemoveMutation,
    GraphQL.SuggestionListSocialAccountCippusRemoveMutationVariables
  >(GraphQL.SuggestionListSocialAccountCippusRemoveDocument, { input })
)

export const removeListContentKeywordPosts = async (
  params: GraphQL.SuggestionListSocialAccountCippiRemoveByKeywordMutationVariables,
): Promise<Status<GraphQL.SuggestionListSocialAccountCippiRemoveByKeywordMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListSocialAccountCippiRemoveByKeywordMutation,
    GraphQL.SuggestionListSocialAccountCippiRemoveByKeywordMutationVariables
  >(GraphQL.SuggestionListSocialAccountCippiRemoveByKeywordDocument, params)
)

export const removeListContentImageTagPosts = async (
  params: GraphQL.SuggestionListSocialAccountCippiRemoveByImageTagMutationVariables,
): Promise<Status<GraphQL.SuggestionListSocialAccountCippiRemoveByImageTagMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListSocialAccountCippiRemoveByImageTagMutation,
    GraphQL.SuggestionListSocialAccountCippiRemoveByImageTagMutationVariables
  >(GraphQL.SuggestionListSocialAccountCippiRemoveByImageTagDocument, params)
)

export const createDeliverable = async (
  params: GraphQL.CreateDeliverableMutationVariables,
): Promise<Status<GraphQL.CreateDeliverableMutation | null>> => (
  API.mutate<
    GraphQL.CreateDeliverableMutation,
    GraphQL.CreateDeliverableMutationVariables
  >(GraphQL.CreateDeliverableDocument, params)
)

export const addCampaignNetworkAccounts = async (
  {
    campaignId,
    networkAccountIds,
  }: GraphQL.CreateCampaignNetworkAccountsMutationVariables,
): Promise<Status<GraphQL.CreateCampaignNetworkAccountsMutation | null>> => (
  API.mutate<
    GraphQL.CreateCampaignNetworkAccountsMutation,
    GraphQL.CreateCampaignNetworkAccountsMutationVariables
    >(GraphQL.CreateCampaignNetworkAccountsDocument, { campaignId, networkAccountIds })
)

export const deleteCampaignNetworkAccounts = async (
  params: GraphQL.DeleteCampaignNetworkAccountMutationVariables,
): Promise<Status<GraphQL.DeleteCampaignNetworkAccountMutation | null>> => (
  API.mutate<
    GraphQL.DeleteCampaignNetworkAccountMutation,
    GraphQL.DeleteCampaignNetworkAccountMutationVariables
  >(GraphQL.DeleteCampaignNetworkAccountDocument, params)
)

export const linkAdCampaignsToCampaign = async (
  params: GraphQL.LinkAdCampaignsToCampaignMutationVariables,
) => (
  API.mutate<
    GraphQL.LinkAdCampaignsToCampaignMutation,
    GraphQL.LinkAdCampaignsToCampaignMutationVariables
  >(GraphQL.LinkAdCampaignsToCampaignDocument, params)
)

export const linkAdsToDeliverable = async (
  params: GraphQL.LinkAdsToDeliverableMutationVariables,
) => (
  API.mutate<
    GraphQL.LinkAdsToDeliverableMutation,
    GraphQL.LinkAdsToDeliverableMutationVariables
  >(GraphQL.LinkAdsToDeliverableDocument, params)
)

export const unlinkAdCampaignToCampaign = async (
  params: GraphQL.UnlinkAdCampaignFromCampaignMutationVariables,
) => (
  API.mutate<
    GraphQL.UnlinkAdCampaignFromCampaignMutation,
    GraphQL.UnlinkAdCampaignFromCampaignMutationVariables
  >(GraphQL.UnlinkAdCampaignFromCampaignDocument, params)
)

export const unlinkAdsToDeliverable = async (
  params: GraphQL.UnlinkAdsFromDeliverableMutationVariables,
) => (
  API.mutate<
    GraphQL.UnlinkAdsFromDeliverableMutation,
    GraphQL.UnlinkAdsFromDeliverableMutationVariables
  >(GraphQL.UnlinkAdsFromDeliverableDocument, params)
)

export const loginViaEmail = async (
  { email, code }: GraphQL.LoginEmailMutationVariables,
): Promise<Status<GraphQL.LoginEmailMutation | null>> => (
  API.mutate<
    GraphQL.LoginEmailMutation,
    GraphQL.LoginEmailMutationVariables
  >(GraphQL.LoginEmailDocument, { email, code }, undefined, undefined, true)
)

export const sendEmailFindTeam = async (
  { email }: GraphQL.TeamEmailMutationVariables,
): Promise<Status<GraphQL.TeamEmailMutation | null>> => (
  API.mutate<
    GraphQL.TeamEmailMutation,
    GraphQL.TeamEmailMutationVariables
  >(GraphQL.TeamEmailDocument, { email }, undefined, undefined, true)
)

export const sendForgotPasswordEmail = async (
  { email, code }: GraphQL.ForgotPasswordEmailMutationVariables,
): Promise<Status<GraphQL.ForgotPasswordEmailMutation | null>> => (
  API.mutate<
    GraphQL.ForgotPasswordEmailMutation,
    GraphQL.ForgotPasswordEmailMutationVariables
  >(
    GraphQL.ForgotPasswordEmailDocument,
    { email, code },
    undefined,
    undefined,
    true,
  )
)

export const approveDeliverableItem = async (
  params: GraphQL.AcceptDeliverableItemMutationVariables,
): Promise<Status<GraphQL.AcceptDeliverableItemMutation | null>> => (
  API.mutate<
    GraphQL.AcceptDeliverableItemMutation,
    GraphQL.AcceptDeliverableItemMutationVariables
  >(
    GraphQL.AcceptDeliverableItemDocument,
    params,
  )
)

export const createDeliverableItemFeedback = async (
  params: GraphQL.CreateDeliverableItemFeedbackMutationVariables,
): Promise<Status<GraphQL.CreateDeliverableItemFeedbackMutation | null>> => (
  API.mutate<
    GraphQL.CreateDeliverableItemFeedbackMutation,
    GraphQL.CreateDeliverableItemFeedbackMutationVariables
  >(
    GraphQL.CreateDeliverableItemFeedbackDocument,
    params,
  )
)

export const updateDeliverable = async (
  params: GraphQL.UpdateDeliverableMutationVariables,
): Promise<Status<GraphQL.UpdateDeliverableMutation | null>> => (
  API.mutate<
    GraphQL.UpdateDeliverableMutation,
    GraphQL.UpdateDeliverableMutationVariables
  >(
    GraphQL.UpdateDeliverableDocument,
    params,
  )
)

export const finalizeDeliverable = async (
  params: GraphQL.FinalizeDeliverableMutationVariables,
): Promise<Status<GraphQL.FinalizeDeliverableMutation | null>> => (
  API.mutate<
    GraphQL.FinalizeDeliverableMutation,
    GraphQL.FinalizeDeliverableMutationVariables
  >(
    GraphQL.FinalizeDeliverableDocument,
    params,
  )
)

export const updateDeliverableLiveStatus = async (
  params: GraphQL.UpdateDeliverableLiveStatusMutationVariables,
): Promise<Status<GraphQL.UpdateDeliverableLiveStatusMutation | null>> => (
  API.mutate<
    GraphQL.UpdateDeliverableLiveStatusMutation,
    GraphQL.UpdateDeliverableLiveStatusMutationVariables
  >(
    GraphQL.UpdateDeliverableLiveStatusDocument,
    params,
  )
)

export const createTctmOrder = async (
  params: GraphQL.CreateTcmOrderMutationVariables,
): Promise<Status<GraphQL.CreateTcmOrderMutation | null>> => (
  API.mutate<
    GraphQL.CreateTcmOrderMutation,
    GraphQL.CreateTcmOrderMutationVariables
  >(
    GraphQL.CreateTcmOrderDocument,
    params,
  )
)

export const addListSocialAccountTopPost = async (
  params: GraphQL.SuggestionListSocialAccountCippusAddMutationVariables,
): Promise<Status<GraphQL.SuggestionListSocialAccountCippusAddMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListSocialAccountCippusAddMutation,
    GraphQL.SuggestionListSocialAccountCippusAddMutationVariables
  >(
    GraphQL.SuggestionListSocialAccountCippusAddDocument,
    params,
  )
)

export const updateSuggestionListGroup = async (
  params: GraphQL.UpdateSuggestionListGroupMutationVariables,
): Promise<Status<GraphQL.UpdateSuggestionListGroupMutation | null>> => (
  API.mutate<
    GraphQL.UpdateSuggestionListGroupMutation,
    GraphQL.UpdateSuggestionListGroupMutationVariables
  >(
    GraphQL.UpdateSuggestionListGroupDocument,
    params,
  )
)

export const suggestionListAddToGroup = async (
  params: GraphQL.SuggestionListAddToGroupMutationVariables,
): Promise<Status<GraphQL.SuggestionListAddToGroupMutation | null>> => (
  API.mutate<
    GraphQL.SuggestionListAddToGroupMutation,
    GraphQL.SuggestionListAddToGroupMutationVariables
  >(
    GraphQL.SuggestionListAddToGroupDocument,
    params,
  )
)
export const createContentAlert = async (
  params: GraphQL.CreateContentAlertMutationVariables,
): Promise<Status<GraphQL.CreateContentAlertMutation | null>> => (
  API.mutate<
    GraphQL.CreateContentAlertMutation,
    GraphQL.CreateContentAlertMutationVariables
  >(
    GraphQL.CreateContentAlertDocument,
    params,
  )
)

export const createSuggestionListVerticalType = async (
  name: string,
) => (
  API.query<
    GraphQL.CreateSuggestionListVerticalTypeMutationMutation,
    GraphQL.CreateSuggestionListVerticalTypeMutationMutationVariables
  >(GraphQL.CreateSuggestionListVerticalTypeMutationDocument, { name })
)

export const createCustomer = async (
  {
    code,
    companyName,
    mediaId,
  }: GraphQL.CreateCustomerMutationVariables,
): Promise<Status<GraphQL.CreateCustomerMutation | null>> => (
  API.mutate<
    GraphQL.CreateCustomerMutation,
    GraphQL.CreateCustomerMutationVariables
    >(GraphQL.CreateCustomerDocument, {
      code, companyName, mediaId,
    })
)

export const createCustomerUser = async (params: GraphQL.CreateUserMutationVariables)
  : Promise<Status<GraphQL.CreateUserMutation | null>> => (
  API.mutate<
    GraphQL.CreateUserMutation,
    GraphQL.CreateUserMutationVariables
    >(GraphQL.CreateUserDocument, params)
)

export const updateCustomerUser = async (params: GraphQL.UpdateUserForCustomerMutationVariables)
  : Promise<Status<GraphQL.UpdateUserForCustomerMutation | null>> => (
  API.mutate<
    GraphQL.UpdateUserForCustomerMutation,
    GraphQL.UpdateUserForCustomerMutationVariables
    >(GraphQL.UpdateUserForCustomerDocument, params)
)

export const updatePasswordForCustomerUser = async (params: GraphQL.ChangePasswordForCustomerUserMutationVariables)
  : Promise<Status<GraphQL.ChangePasswordForCustomerUserMutation | null>> => (
  API.mutate<
    GraphQL.ChangePasswordForCustomerUserMutation,
    GraphQL.ChangePasswordForCustomerUserMutationVariables
    >(GraphQL.ChangePasswordForCustomerUserDocument, params)
)
