import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

export interface CreateCustomer {
  isModalOpen: boolean,
  newCustomer: Status<GraphQL.CreateCustomerMutation | null>,
}

const initialState: CreateCustomer = {
  isModalOpen: false,
  newCustomer: "init",
}

// Create Customer Slice
export const createCustomerSlice = createSlice({
  name: "CreateCustomer",
  initialState,
  reducers: {
    setCreateCustomerModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      isModalOpen: action.payload,
    }),
    newCustomer: (
      state,
      action: PayloadAction<Status<GraphQL.CreateCustomerMutation | null>>,
    ) => ({
      ...state,
      newCustomer: action.payload,
    }),
    resetCustomer: (
      state,
    ) => ({
      ...state,
      newCustomer: "init",
    }),
  },
})

export const {
  setCreateCustomerModalOpen, newCustomer, resetCustomer,
} = createCustomerSlice.actions
export default createCustomerSlice.reducer

// Thunks
export const createNewCustomer = (
  {
    code,
    companyName,
    mediaId,
  }: GraphQL.CreateCustomerMutationVariables,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(newCustomer("loading"))
  const customerInfo = await API.createCustomer({
    code, companyName, mediaId,
  })
  dispatch(newCustomer(customerInfo))
}
